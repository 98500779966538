import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      error: "#FF210B",
    },
    secondary: {
      main: "rgba(0,0,0,0.6)",
    },
  },
  typography: {
    fontFamily: ["DM Sans", "DM Mono", "sans-serif", "monospace"].join(","),
    body1: {
      fontWeight: 700,
      fontSize: "1rem",
      "@media (min-width: 960px)": {
        fontSize: "1.5rem",
      },
    },
    h1: {
      fontFamily: "DM Mono, monospace",
      "@media (max-width:599px)": {
        fontSize: "3rem",
      },
    },
    h2: {
      fontFamily: "DM Mono, monospace",
      "@media (max-width:599px)": {
        fontSize: "2.125rem",
      },
    },
    h3: {
      fontFamily: "DM Mono, monospace",
      "@media (max-width:599px)": {
        fontSize: "1.5rem",
      },
    },
    h4: {
      fontFamily: "DM Mono, monospace",
    },
    h5: {
      fontSize: "2rem",
      fontFamily: "DM Mono, monospace",
      "@media (max-width:599px)": {
        fontSize: "1.5rem",
      },
    },
    h6: {
      fontFamily: "DM Mono, monospace",
    },
    button: {
      fontFamily: "DM Mono, monospace",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: "rgba(255,255,255,0.9)",
        color: "#000",
        border: "2px solid #fff",
        "&:hover": {
          backgroundColor: "rgba(255,255,255,1)",
        },
      },
      outlined: {
        border: "1px solid #000",
        backgroundColor: "#fff",
        color: "#000",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#000 !important",
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-focused": {
          color: "red !important",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: "#fff",
        color: "#000",
        boxShadow:
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        border: "1px solid #000",
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "rgba(0, 0, 0, 0.25)",
        boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
        backdropFilter: "blur(4px)",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
        color: "#fff",
      },
    },
  },
  components: {
    MuiPaper: {
      variants: [
        {
          props: { variant: "blur" },
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
            backdropFilter: "blur(4px)",
            border: "1px solid rgba( 255, 255, 255, 0.18 )",
            color: "#fff",
          },
        },
      ],
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },

  custom: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    colorSet: [
      "#FFFFFF",
      "#9C9C9C",
      "#A7226E",
      "#EC2049",
      "#F26B38",
      "#F7DB4F",
      "#2F9599",
    ],
  },
});

export default theme;
