import * as React from "react";
import { StyledEngineProvider } from "@mui/material/styles";

// this allows for className to have priority over mui
const GlobalCssPriority = (props) => {
  return (
    <StyledEngineProvider injectFirst>{props.children}</StyledEngineProvider>
  );
};

export default GlobalCssPriority;
