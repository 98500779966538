exports.components = {
  "component---src-pages-capability-statement-js": () => import("./../../../src/pages/capability-statement.js" /* webpackChunkName: "component---src-pages-capability-statement-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-js": () => import("./../../../src/pages/digital.js" /* webpackChunkName: "component---src-pages-digital-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-wip-digital-showroom-js": () => import("./../../../src/pages/wip/digital-showroom.js" /* webpackChunkName: "component---src-pages-wip-digital-showroom-js" */),
  "component---src-pages-wip-tuleva-js": () => import("./../../../src/pages/wip/tuleva.js" /* webpackChunkName: "component---src-pages-wip-tuleva-js" */),
  "component---src-pages-words-js": () => import("./../../../src/pages/words.js" /* webpackChunkName: "component---src-pages-words-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

